<template>
  <div>
    <c-modal
      :is-open="isOpen"
      :on-close="close"
      size="md"
      is-centered
    >
      <c-modal-content
        ref="content"
        mx="16px"
        py="16px"
        rounded="8px"
      >
        <c-modal-header
          m="auto"
          pb="0px"
        >
          <CImage
            size="150px"
            object-fit="cover"
            :src="imageIllustration"
            alt="Image Confirmation"
          />
        </c-modal-header>
        <c-modal-body
          text-align="center"
          color="#008C81"
        >
          <c-text
            font-weight="700"
            :font-size="['18px','20px']"
            mb="16px"
          >
            Program Tidak Dapat Dibeli
          </c-text>
          <c-text
            font-weight="400"
            :font-size="['14px', '16px']"
            color="#555555"
          >
            Kamu masih memiliki program aktif lainnya.  Silahkan menunggu hingga program selesai atau lakukan pembelian dengan jenis program yang sama.
          </c-text>
        </c-modal-body>
        <c-modal-footer
          d="flex"
          gap="16px"
        >
          <c-button
            rounded="1000px"
            variant-color="primary"
            font-size="14px"
            font-weight="500"
            w="100%"
            @click="close"
          >
            Kembali
          </c-button>
        </c-modal-footer>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import iconCircleClose from '@/assets/icons/icon-circle-close.svg'
import iconCircleCheck from '@/assets/icons/icon-circle-check.svg'
import imageIllustration from '@/assets/images/illustration-130323-raw.jpeg'

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconCircleClose,
      iconCircleCheck,
      imageIllustration,
    }
  },
  methods: {
    close() {
      this.$emit('handleClose', 'ModalErrorProgramCannotPurchase')
    },
  },
}
</script>