<template>
  <CBox
    v-if="!!selectedCart.productService"
    w="100%"
    :bg="['#F2F2F2', 'white']"
    :h="['calc(100vh - 64px)', '100%']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          { label: 'Dashboard', href: '/' },
          { label: 'Keranjang', href: '/cart' },
          { label: 'Periksa Pesanan',isCurrent: true },
        ]"
      />
    </Portal>

    <CText
      :d="['none', 'block']"
      text-align="center"
      font-family="Roboto"
      font-size="20px"
      font-weight="700"
      mb="1rem"
      color="#333"
    >
      Periksa Pesanan
    </CText>
    <CBox
      d="flex"
      w="100%"
      :flex-dir="['column', 'row']"
      px="16px"
      gap="16px"
    >
      <!--      http://localhost:8080/buy/3?category_id=5239edab-0353-4bff-900d-1980ed7eb94f&product_id=64e85255-a134-4d4d-82c2-9d269014428f&product_service_id=29a1895c-74c0-4761-abf2-3528f1e4a3b4&duration=30-->

      <CBox
        :bg="['white', 'white']"
        width="100%"
        :p="['16px', '30px']"
        mt="16px"
        :mb="['316px', '0px']"
        :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
        :border-radius="['none', '12px']"
      >
        <ElementCheckOrderInfoProgram
          :photo-url="selectedCart.productService?.product?.photoUrl"
          :name="selectedCart.productService?.product?.name"
          :sub-name="selectedCart?.productService?.programsService + ' ' + '(' + selectedCart?.productService?.duration * selectedCart.quantity + ' Hari)'"
          :route-change-program="{ name: 'client.select-program' }"
          :more-info-url="getHandleMore"
          :price="price * selectedCart.quantity"
          :coupon-code="couponCode"
          :coupon-status="statusAppliedCoupon"
          :is-applying-coupon="isLoading.applyCoupon"
          @set-coupon-code="handleChangeCoupon"
          @handle-click-check-coupon="handleCheckCoupon"
          :has-phone-number="hasPhoneNumberBefore"
          :is-valid-phone="phone?.length >= 1 ? isValidPhone && formState?.success : true"
          :info-message-phone="phone?.length >= 1 ? formState?.error?.issues?.[0]?.message : null"
          :phone="phone"
          @set-phone="(e) => {
            phone = e.target.value
            isValidPhone = true
          }"
          :coupon-detail="couponDetail"
        />
      </CBox>

      <CBox
        mt="16px"
        :bg="['white', 'white']"
        :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
        :rounded-top="['12px', '12px']"
        :rounded-bottom="['none', '12px']"
        :position="['fixed', 'unset']"
        bottom="0"
        left="0"
        w="100%"
        :p="['16px', '30px']"
        d="flex"
        flex-dir="column"
        align-items="center"
        justify-content="center"
      >
        <CBox
          w="100%"
        >
          <ElementCheckOrderInfoPrice
            :price="price * selectedCart.quantity"
            :discount="discount"
            :final-price="price * selectedCart.quantity - discount"
          />
          <CButton
            :font-size="['14px', '16px']"
            :font-weight="['500', 'Regular']"
            variant-color="primary"
            mt="20px"
            w="100%"
            rounded="1000px"
            size="lg"
            :disabled="isLoading.checkout || !formState.success"
            @click="handleCheckout"
          >
            Lakukan Pembayaran
          </CButton>
        </CBox>
      </CBox>
    </CBox>

    <!-- MODAL -->
    <ModalCouponInvalid
      :is-open="isModalOpen.ModalCouponInvalid"
      @handleClose="handleCloseModal"
    />
    <ModalErrorProgramCannotPurchase
      :is-open="isModalOpen.ModalErrorProgramCannotPurchase"
      @handleClose="handleCloseModal"
    />
    <ModalErrorProgramCannotPurchaseKonsultation
      :is-open="isModalOpen.ModalErrorProgramCannotPurchaseKonsultation"
      @handleClose="handleCloseModal"
    />
  </CBox>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import { Portal } from 'portal-vue'
import { CBox, CText } from '@chakra-ui/vue'
import iconCoupon from '@/assets/icons/icon-coupon.svg'
import { formatCurrency } from '@/utils/format-currency'
import iconArrowRight from '@/assets/icons/icon-arrow-right.svg'
import ModalCouponInvalid from '@/views/client/cart/check-order/_widgets/modal-coupon-invalid.vue'
import ModalErrorProgramCannotPurchase from '@/views/client/cart/check-order/_widgets/modal-error-program-cannot-purchase.vue'
import ModalErrorProgramCannotPurchaseKonsultation from '@/views/client/cart/check-order/_widgets/modal-error-program-cannot-purchase-konsultation.vue'
import ElementCheckOrderInfoProgram from '@/components/element-check-order/element-check-order-info-program.vue'
import ElementCheckOrderInfoPrice from '@/components/element-check-order/element-check-order-info-price.vue'
import { z } from 'zod'

export default {
  components: {
    ElementCheckOrderInfoPrice,
    ElementCheckOrderInfoProgram,
    BreadcrumbPath,
    Portal,
    CBox,
    CText,
    ModalCouponInvalid,
    ModalErrorProgramCannotPurchase,
    ModalErrorProgramCannotPurchaseKonsultation,
  },
  data() {
    return {
      couponCode: '',
      selectedItem: {},
      iconCoupon,
      couponDetail: {
        id: '',
        type: '',
        totalDiscount: 0,
        isValid: null,
        isSkipPayment: 0,
      },
      isLoading: {
        applyCoupon: false,
        checkout: false,
      },
      isModalOpen: {
        ModalCouponInvalid: false,
        ModalErrorProgramCannotPurchase: false,
        ModalErrorProgramCannotPurchaseKonsultation: false,
      },
      iconArrowRight,
      hasPhoneNumberBefore: false,
      isValidPhone: true,
      phone: '',
      dirtyField: {
        phone: false,
        couponCode: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      selectedCart: 'cart/selectedCart',
      userType: 'userType/userType',
    }),
    formState() {
      if (this.hasPhoneNumberBefore) {
        return {
          success: true,
        }
      }
      const user = z.object({
        phone: z.string().min(1, { message: 'Nomor telepon tidak boleh kurang dari 1 digit' }).max(13, { message: 'Nomor telepon tidak boleh melebih 13 digit' }).refine((value) =>
          /^\d+$/.test(value)
        , { message: 'Nomor telepon mohon diisi dengan angka' }),
        couponCode: z.string().nullable(),
      })
      return user.safeParse({
        phone: this.phone,
        couponCode: this.couponCode,
      })
    },
    getHandleMore() {
      if (this.selectedCart?.productService?.isKonsultasi === 1) {
        return {
          name: 'client.program.consultation',
        }
      }
      if (this.selectedCart?.productService?.isExtend === 1) {
        return {
          name: 'client.program.extend',
        }
      }
      return {
        name: 'client.program.detail',
        params: { id: this.selectedCart?.productService?.product?.slug ? this.selectedCart?.productService?.product?.slug : this.selectedCart?.productId },
      }
    },
    price() {
      return this.selectedCart?.productService?.discountedPrice || this.selectedCart?.productService?.price
    },
    discount() {
      if (this.couponDetail.type === 'percent') {
        return (this.couponDetail.totalDiscount / 100) * (this.price * this.selectedCart.quantity)
      }
      return this.couponDetail.totalDiscount
    },
   
    statusAppliedCoupon() {
      if (this.couponDetail.isValid === null) {
        return 'not-applied'
      } else if (this.couponCode && this.couponDetail.isValid) {
        return 'success'
      } else if (!(this.couponCode && this.couponDetail.isValid)) {
        return 'failed'
      }
      return 'not-applied'
    },
  },
  async mounted() {
    const profile = await this.getProfileClient()
    this.hasPhoneNumberBefore = !!profile?.phone
    this.phone = profile?.phone

    if (!this.selectedCart?.productService) {
      this.$router.push({ name: 'client.cart' })
      return
    }
    const res = await this.getProductServiceId(this.selectedCart?.productService?.id)

    this.setSelectedCart({
      ...this.selectedCart,
      productService: {
        id: res.data.id,
        programsService: res.data.programsService,
        duration: res.data.duration,
        price: res.data.price,
        isExtend: res.data.isExtend,
        discountedPrice: res.data.discountedPrice,
        productId: res.data.productId,
        isKonsultasi: res.data.isKonsultasi,
        product: {
          photoUrl: res.data.product.photoUrl,
          name: res.data.product.name,
          slug: null,
        },
      },
    })
    this.triggerUpdateUserType()
  },
  methods: {
    ...mapMutations({
      setSelectedCart: 'cart/setSelectedCart',
      setCouponId: 'generalPersist/setCouponId',
      setFetchPrograms: 'clients/setFetchPrograms',
    }),
    ...mapActions({
      getProductServiceId: 'general/getProductServiceId',
      fetchListCart: 'cart/list',
      detailProduct: 'clients/getProductById',
      onOpenModal: 'clients/onToggleModal',
      reqCheckCoupon: 'clients/reqCheckCoupon',
      reqCheckout: 'clients/reqCheckout',
      triggerUpdateUserType: 'userType/triggerUpdateUserType',
      getProfileClient: 'profile/getProfileClient',
      updateProfile: 'profile/updateProfileClient',
    }),
    resetCouponDetail() {
      this.couponDetail = {
        id: '',
        type: '',
        totalDiscount: 0,
        isValid: null,
        isSkipPayment: 0,
      }
    },
    formatCurrency,
    handleChangeCoupon(e) {
      if (this.couponDetail.isValid !== null) {
        this.resetCouponDetail()
      }
      this.couponCode = e.target.value
    },
    async handleCheckCoupon() {
      if (this.statusAppliedCoupon === 'success') {
        this.couponCode = ''
        this.resetCouponDetail()
        return
      }

      this.isLoading.applyCoupon = true
      try {
        const res = await this.reqCheckCoupon({
          couponCode: this.couponCode,
          productServiceId: this.selectedCart?.productService?.id,
        })
        this.couponDetail = res.data
        this.couponDetail.isValid = true
      } catch (e) {
        this.isModalOpen.ModalCouponInvalid = true
        this.couponDetail.isValid = false
        console.error(e)
      }
      this.isLoading.applyCoupon = false
    },
    async handleCheckout() {
      try {
        this.isLoading.checkout = true
        if (!this.hasPhoneNumberBefore) {
          const resUpdate = await this.updateProfile({
            profile: {
              phone: this.phone,
            },
          })
          if (resUpdate.status === false) {
            this.isValidPhone = false
            return
          }
        }

        this.setCouponId(this.couponDetail.id)
        const res = await this.reqCheckout({
          cartId: this.selectedCart.id,
          productServiceId: this.selectedCart?.productService?.id,
          couponId: this.couponDetail.id,
          qty: this.selectedCart.quantity,
        })

        // POSTHOG capture `order created`
        const selectedProgram = {
          productService: this.selectedCart,
          coupon: this.couponDetail.isValid ? this.couponDetail : null,
          qty: this.selectedCart.quantity,
        }
        this.$posthog.capture('order created', {
          userType: this.userType ?? 'not-registered',
          program: selectedProgram,
        })
        window.fbq('track', 'CheckOut')

        if (res?.data?.isSkipPayment) {
          this.setFetchPrograms(true)
          if (res?.data?.isChooseNutritionist === 0) {
            this.$router.replace({
              name: 'client.checkout.done',
              query: { 
                transaction_id: res.data?.id,
                program_id: res.data?.programId,
                choose_nutri: res.data?.isChooseNutritionist,
              },
            }).catch(() => {})
          } else {
            this.$router.replace({
              name: 'client.checkout.done',
              query: { transaction_id: res.data?.id },
            }).catch(() => {})
          }
        } else {
          this.$router.replace({
            name: 'client.checkout.payment',
            params: { id: res.data?.id },
          })
        }
        await this.fetchListCart()
      } catch (e) {
        if (this.selectedCart?.productService?.isKonsultasi === 1) {
          this.isModalOpen.ModalErrorProgramCannotPurchaseKonsultation = true
        } else {
          this.isModalOpen.ModalErrorProgramCannotPurchase = true
        }
      } finally {
        this.isLoading.checkout = false
      }
    },
    handleCloseModal(type = 'ModalCouponInvalid') {
      if (type === 'ModalCouponInvalid') {
        this.isModalOpen.ModalCouponInvalid = false
      } else if (type === 'ModalErrorProgramCannotPurchase' && this.selectedCart?.productService?.isKonsultasi === 1) {
        this.isModalOpen.ModalErrorProgramCannotPurchaseKonsultation = false
      } else if (type === 'ModalErrorProgramCannotPurchase') {
        this.isModalOpen.ModalErrorProgramCannotPurchase = false
      }
    },
  },
}
</script>
