var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "on-close": _vm.close,
      "size": "md",
      "is-centered": ""
    }
  }, [_c('c-modal-content', {
    ref: "content",
    attrs: {
      "mx": "16px",
      "py": "16px",
      "rounded": "8px"
    }
  }, [_c('c-modal-header', {
    attrs: {
      "m": "auto",
      "pb": "0px"
    }
  }, [_c('CImage', {
    attrs: {
      "size": "150px",
      "object-fit": "cover",
      "src": _vm.imageIllustration,
      "alt": "Image Confirmation"
    }
  })], 1), _c('c-modal-body', {
    attrs: {
      "text-align": "center",
      "color": "#008C81"
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "700",
      "font-size": ['18px', '20px'],
      "mb": "16px"
    }
  }, [_vm._v(" Program Tidak Dapat Dibeli ")]), _c('c-text', {
    attrs: {
      "font-weight": "400",
      "font-size": ['14px', '16px'],
      "color": "#555555"
    }
  }, [_vm._v(" Kamu masih memiliki program aktif lainnya. Silahkan menunggu hingga program selesai atau lakukan pembelian dengan jenis program yang sama. ")])], 1), _c('c-modal-footer', {
    attrs: {
      "d": "flex",
      "gap": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "rounded": "1000px",
      "variant-color": "primary",
      "font-size": "14px",
      "font-weight": "500",
      "w": "100%"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Kembali ")])], 1)], 1), _c('c-modal-overlay')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }